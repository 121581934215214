/*eslint-disable */
import root from '@/_helpers/roots';
import axios from 'axios';
let patientWithChatRoomCreatedApiUrl = root.patientWithChatRoomCreatedApiUrl.link;
let patientWithChatRoomDetailApiUrl = root.patientWithChatRoomDetailApiUrl.link;
let generateChatRoomId = root.generateChatRoomId.link;


export default {
  state: {
  },
  mutations: {
  },
  actions: {
    generateChatRoomIdMethod(context, payload) {
      return new Promise((resolve, reject) => {
        axios.post(generateChatRoomId, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
      })
    },
    createSupportChatRoomForPatientMethod(context, payload) {
      return new Promise((resolve, reject) => {
        var instance = axios.create();
        delete instance.defaults.headers.common['Authorization'];
        instance.post(patientWithChatRoomCreatedApiUrl, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
      })
    },
    createChatRoomForPatientMethod(context, payload) {
      return new Promise((resolve, reject) => {
        var instance = axios.create();
        delete instance.defaults.headers.common['Authorization'];
        instance.post(patientWithChatRoomCreatedApiUrl + payload.data.id + '/', payload.data.data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
      })
    },
    getChatRoomDetailForPatientMethod(context, payload) {
      return new Promise((resolve, reject) => {
        var instance = axios.create();
        delete instance.defaults.headers.common['Authorization'];
        instance.get(patientWithChatRoomDetailApiUrl + payload.id + '/')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
      })
    },
  },
  getters: {
  }
}