/*eslint-disable */

import Push from 'push.js';
import {bus} from '@/main';

export default {
  name: 'after-login-header',
  components: {},
  props: [],
  data () {
    return {
      active:false,
      showBtn:true,
      navOpen: false,
      isMobile:false
    }
  },
  computed: {

  },
  mounted () {
    bus.$on('showHeaderbtn', (data) => {
      this.showBtn = data;
    });
    bus.$on('updateMessageNotifications', (data) => {
      if(this.$route.name != 'ngoWorkspace'){
      if(Push.Permission.has()){
        Push.create(data.msgSenderFullName, {
          body:data.senderMsgData,
          timeout:4000,
          onClick: function () {
              window.focus();
              this.close();
          }
        })
      }
      }
    });
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 1023;
    },
    logOutUser() {
      if ('BroadcastChannel' in self) {
        authChannel.postMessage({
          cmd: 'logout'
        })
      }
    },
    logout(){
      this.$store.dispatch('logout')
        .then(() => {
          this.logOutUser()
          this.$router.push('/')
        })
    },
  },
  created() {
    window.addEventListener('resize', this.onResize, {
      passive: true
    })
    this.onResize()
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, {
        passive: true
      })
    }
  },
}