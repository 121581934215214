import { render, staticRenderFns } from "./before-login-header.html?vue&type=template&id=dca984e6&scoped=true&"
import script from "./before-login-header.js?vue&type=script&lang=js&"
export * from "./before-login-header.js?vue&type=script&lang=js&"
import style0 from "./before-login-header.scss?vue&type=style&index=0&id=dca984e6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dca984e6",
  null
  
)

export default component.exports