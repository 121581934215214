/*eslint-disable */
import root from '@/_helpers/roots';
import axios from 'axios';
import Vue from 'vue'
let doctorsListApiUrl = root.doctorsListApiUrl.link;
let categoryListOfProductApiUrl = root.categoryListOfProductApiUrl.link;
let categoryDetailOfProductApiUrl = root.categoryDetailOfProductApiUrl.link;
let searchDoctorApiUrl = root.searchDoctorApiUrl.link;
let doctorPresListApiUrl = root.doctorPresListApiUrl.link;
let doctorInitialMessageApiUrl = root.doctorInitialMessageApiUrl.link;
let favouriteDoctorApiUrl = root.favouriteDoctorApiUrl.link;
let favouriteDoctorListApiUrl = root.favouriteDoctorListApiUrl.link;
let doctorDetailByIdApiUrl = root.doctorDetailByIdApiUrl.link;

export default {
  state: {
    doctorstListState:{},
    doctorsFiltersListState:{},
    doctorsListByFilterState:{},
    doctorDetailByIdState:{},
    getDoctorPrescriptionsState:{},
    getDoctorFavouriteListState:{}
  },
  mutations: {
    doctorstListMutation(state, value) {
      Vue.set(state, 'doctorstListState', value);
    },
    doctorsFiltersListMutation(state, value) {
      Vue.set(state, 'doctorsFiltersListState', value);
    },
    doctorsListByFilterMutation(state, value) {
      Vue.set(state, 'doctorsListByFilterState', value);
    },
    doctorDetailByIdMutation(state, value){
      Vue.set(state, 'doctorDetailByIdState', value);
    },
    getDoctorPrescriptionsMutations(state, value){
      Vue.set(state, 'getDoctorPrescriptionsState', value);
    },
    getDoctorFavouriteListMutation(state, value){
      Vue.set(state, 'getDoctorFavouriteListState', value);
    }
  },
  actions: {
    // Get Doctor Info By Id Action Function
    getDoctorDetailByIdFun(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(doctorDetailByIdApiUrl + payload.id + '/').then(response => {
             resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Get Doctor Favourite List Action Function
    getDoctorFavouriteListFun(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(favouriteDoctorListApiUrl, payload).then(response => {
             context.commit('getDoctorFavouriteListMutation', response.data.data);
             resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Make A Doctor Un Favourite Action Function
    makeDoctorUnFavouriteFun(context, payload) {
        return new Promise((resolve, reject) => {
            axios.delete(favouriteDoctorApiUrl + payload.id + '/').then(response => {
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Make A Doctor Favourite Action Function
    makeDoctorFavouriteFun(context, payload) {
        return new Promise((resolve, reject) => {
            axios.post(favouriteDoctorApiUrl, payload).then(response => {
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Doctor Initial Message Action Function
    doctorInitialMessageFun(context, payload) {
        return new Promise((resolve, reject) => {
            axios.post(doctorInitialMessageApiUrl, payload).then(response => {
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Get Doctors Prescription List Action Function
    getDoctorPrescriptionsListApiFun(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(doctorPresListApiUrl , payload).then(response => {
            context.commit('getDoctorPrescriptionsMutations', response.data);
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Get Doctors List Action Function
    getDoctorsListApiFun(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(doctorsListApiUrl, payload).then(response => {
            context.commit('doctorstListMutation', response.data.data);
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Get Doctors Details BY ID Action Function
    getDoctorsDetailByIdApiFun(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(doctorsListApiUrl + payload.id + '/', payload).then(response => {
            context.commit('doctorDetailByIdMutation', response.data);
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Get Doctors Filters List Action Function
    getDoctorsFiltersListApiFun(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(categoryListOfProductApiUrl, payload).then(response => {
            context.commit('doctorsFiltersListMutation', response.data.data);
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Get Doctors List By Filters Action Function
    getDoctorsListByFilterIdApiFun(context, payload) {
        return new Promise((resolve, reject) => {
            axios.get(categoryDetailOfProductApiUrl, payload).then(response => {
            context.commit('doctorsListByFilterMutation', response.data);
            resolve(response);
            }).catch(err => {
            reject(err);
            })
        })
    },
    // Doctor User Search Function
    doctorUserSearchFun(context,payload){
      return new Promise ((resolve, reject) => {
         axios.post(searchDoctorApiUrl, payload).then((res)=>{
          resolve(res);
         }).catch((err)=>{
          reject(err);
         })
      })
    },
  },
  getters: {
    doctorstListGetters : state => state.doctorstListState,
    doctorsFiltersListGetters : state => state.doctorsFiltersListState,
    doctorsListByFilterGetters : state => state.doctorsListByFilterState,
    doctorDetailByIdGetters : state => state.doctorDetailByIdState,
    getDoctorPrescriptionsGetters : state => state.getDoctorPrescriptionsState,
    getDoctorFavouriteListGetters : state => state.getDoctorFavouriteListState
  }
}