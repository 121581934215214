/*eslint-disable */
import {bus} from '@/main';
import header from '@/components//common/header/';
import footer from '@/components/common/footer/';
export default {
  name: 'app',
  components: {
    'app-header':header,
    'app-footer' : footer,
  },
  props: [],
  data () {
    return {
    }
  },
  computed: {

  },
  mounted () {
  },
  methods: {

  }
}