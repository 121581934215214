/*eslint-disable */

import Router from 'vue-router'
import Vue from 'vue'
import store from './store/store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  canReuse: false,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
      };
    } else {
      return {
        x: 0,
        y: 0
      };
    }
  },
  routes: [
    {
      path: '/',
      name: 'MainHome',
      component: () => import( /* webpackChunkName: "MainHome" */ './view/Public/landing/index.vue'),
      guest: true,
      beforeEnter: (to, from, next) => {
        document.title = 'LBIL - Home',
        next();
      }
    },
    // {
    //   path: '/login/',
    //   name: 'login',
    //   component: () => import( /* webpackChunkName: "Login" */ './view/Authentication/login/index.vue'),
    //   guest: true,
    //   beforeEnter: (to, from, next) => {
    //     document.title = 'LBIL - Login',
    //     next();
    //   }
    // },
    // {
    //   path: '/signup/',
    //   name: 'signup',
    //   component: () => import( /* webpackChunkName: "Signup" */ './view/Authentication/signup/index.vue'),
    //   guest: true,
    //   beforeEnter: (to, from, next) => {
    //     document.title = 'LBIL - Signup',
    //     next();
    //   }
    // },
    {
      path: '/account/reset-password/',
      name: 'forgotPw',
      component: () => import( /* webpackChunkName: "forgotPw" */ './view/Authentication/forgot-pw/index.vue'),
      guest: true,
      beforeEnter: (to, from, next) => {
        document.title = 'LBIL - Password Reset',
        next();
      }
    },
    {
      path: '/public/prescription/:presId',
      name: 'publicPrescription',
      component: () => import( /* webpackChunkName: "publicPrescription" */ './view/Public/publicPresInfo/index.vue'),
      guest: true,
      beforeEnter: (to, from, next) => {
        store.dispatch('publicPrescriptionDataApiFun', {id:to.params.presId})
        document.title = 'LBIL - Prescription',
        next();
      }
    },
    {
      path: '/user/agreement/',
      name: 'userAgreement',
      component: () => import( /* webpackChunkName: "userAgreement" */ './view/Public/user-agreement/index.vue'),
      guest: true,
      beforeEnter: (to, from, next) => {
        document.title = 'LBIL - User Agreement',
        next();
      }
    },
    {
      path: '/privacy-policy/',
      name: 'privacyPolicy',
      component: () => import( /* webpackChunkName: "privacyPolicy" */ './view/Public/privacy-policy/index.vue'),
      guest: true,
      beforeEnter: (to, from, next) => {
        document.title = 'LBIL - Privacy Policy',
        next();
      }
    },
    {
      path: '/contact-us/',
      name: 'contactUs',
      component: () => import( /* webpackChunkName: "contactUs" */ './view/Public/contact-us/index.vue'),
      guest: true,
      beforeEnter: (to, from, next) => {
        document.title = 'LBIL - Contact Us',
        next();
      }
    },
    {
      path: '/about-us/',
      name: 'aboutUs',
      component: () => import( /* webpackChunkName: "aboutUs" */ './view/Public/about-us-page/index.vue'),
      guest: true,
      beforeEnter: (to, from, next) => {
        document.title = 'LBIL - About Us',
        next();
      }
    },
    {
      path: '/public/ngo/info/:ngoId',
      name: 'publicNgoInfo',
      component: () => import( /* webpackChunkName: "publicNgoInfo" */ './view/Public/ngo-info/index.vue'),
      guest: true,
      beforeEnter: (to, from, next) => {
        document.title = 'LBIL - Ngo Info',
        next();
      }
    },
    {
      path: '/public/ngo/list/',
      name: 'publicNgoList',
      component: () => import( /* webpackChunkName: "publicNgoList" */ './view/Public/public-ngo-list/index.vue'),
      guest: true,
      beforeEnter: (to, from, next) => {
        store.dispatch('getAllPublicNgoListFunc')
        document.title = 'LBIL - Ngo List',
        next();
      }
    },
    {
      path: '/launch/conference/info/',
      name: 'launchConferenceInfo',
      component: () => import( /* webpackChunkName: "launchConferenceInfo" */ './view/Public/lbil_launch_webinar/index.vue'),
      guest: true,
      beforeEnter: (to, from, next) => {
        document.title = 'LBIL - Launch Conference Info',
        next();
      }
    },
    {
      path: '/dashboard/',
      component: () => import( /* webpackChunkName: "Dashboard" */ './view/Private/dashboard/index.vue'),
      beforeEnter: (to, from, next) => {
        next();
      },
      children: [
        {
          path: '/',
          redirect: {
            name: 'DashboardMain'
          }
        },
        {
          path: '/dashboard/',
          name: 'DashboardMain',
          component: () => import( /* webpackChunkName: "DashboardMain" */ './view/Private/dashboard-main/index.vue'),
          props: (route) => ({
            to: route.query.to,
            stage: route.query.stage,
            bg: route.query.bg
          }),
          beforeEnter: (to, from, next) => {
            store.dispatch('getSelfUserInfoApiFun')
            store.dispatch('getRecentAppointmentListApiFun')
            document.title = 'LBIL - Dashboard'
            next();
          }
        },
        {
          path: '/specialties/',
          name: 'onlineDoctors',
          component: () => import( /* webpackChunkName: "onlineDoctors" */ './view/Private/doctor-specialties/index.vue'),
          props: (route) => ({
            to: route.query.to,
            stage: route.query.stage,
            bg: route.query.bg
          }),
          beforeEnter: (to, from, next) => {
            store.dispatch('getDoctorsFiltersListApiFun')
            document.title = 'LBIL - Online Doctor'
            next();
          }
        },
        {
          path: '/appointment/',
          name: 'appointmentList',
          component: () => import( /* webpackChunkName: "appointmentList" */ './view/Private/appointment/index.vue'),
          props: (route) => ({
            to: route.query.to,
            stage: route.query.stage,
            bg: route.query.bg
          }),
          beforeEnter: (to, from, next) => {
            store.dispatch('getAppointmentListApiFun')
            document.title = 'LBIL - Appointment'
            next();
          }
        },
        {
          path: '/appointment/detail/:appointmentId',
          name: 'appointmentDetail',
          component: () => import( /* webpackChunkName: "appointmentDetail" */ './view/Private/appointment/detail/index.vue'),
          props: (route) => ({
            to: route.query.to,
            stage: route.query.stage,
            bg: route.query.bg
          }),
          beforeEnter: (to, from, next) => {
            store.dispatch('getAppointmentDetailByIdApiFun', {id:to.params.appointmentId})
            document.title = 'LBIL - Appointment Detail'
            next();
          }
        },
        {
          path: '/pay-online-consult/:appointmentId',
          name: 'paymentCheckout',
          component: () => import( /* webpackChunkName: "paymentCheckout" */ './view/Private/payment-checkout/index.vue'),
          props: (route) => ({
            to: route.query.to,
            stage: route.query.stage,
            bg: route.query.bg
          }),
          beforeEnter: (to, from, next) => {
            store.dispatch('getAppointmentDetailByIdApiFun', {id:to.params.appointmentId})
            document.title = 'LBIL - Payment'
            next();
          }
        },
        {
          path: '/payment/success/',
          name: 'paymentSuccess',
          component: () => import( /* webpackChunkName: "paymentSuccess" */ './view/Private/payment-success/index.vue'),
          props: (route) => ({
            to: route.query.to,
            stage: route.query.stage,
            bg: route.query.bg
          }),
          beforeEnter: (to, from, next) => {
            document.title = 'LBIL - Payment Success'
            next();
          }
        },
        {
          path: '/payment/failed/',
          name: 'paymentFailed',
          component: () => import( /* webpackChunkName: "paymentFailed" */ './view/Private/payment-failed/index.vue'),
          props: (route) => ({
            to: route.query.to,
            stage: route.query.stage,
            bg: route.query.bg
          }),
          beforeEnter: (to, from, next) => {
            document.title = 'LBIL - Payment Failed'
            next();
          }
        },
        {
          path: '/prescription/',
          name: 'prescriptionList',
          component: () => import( /* webpackChunkName: "prescriptionList" */ './view/Private/presInfo-list/index.vue'),
          props: (route) => ({
            to: route.query.to,
            stage: route.query.stage,
            bg: route.query.bg
          }),
          beforeEnter: (to, from, next) => {
            store.dispatch('getSelfUserInfoApiFun')
            store.dispatch('getDoctorPrescriptionsListApiFun')
            document.title = 'LBIL - Prescription'
            next();
          }
        },
        {
          path: '/ngo-and-support/',
          name: 'ngoAndSupport',
          component: () => import( /* webpackChunkName: "ngoAndSupport" */ './view/Private/ngo-and-support/index.vue'),
          props: (route) => ({
            to: route.query.to,
            stage: route.query.stage,
            bg: route.query.bg
          }),
          beforeEnter: (to, from, next) => {
            store.dispatch('getPublicNgoListFun')
            document.title = 'LBIL - NGO And Support'
            next();
          }
        },
        {
          path: '/my-ngo-and-support/',
          name: 'myNgoAndSupport',
          component: () => import( /* webpackChunkName: "myNgoAndSupport" */ './view/Private/my-ngo-support-group-list/index.vue'),
          props: (route) => ({
            to: route.query.to,
            stage: route.query.stage,
            bg: route.query.bg
          }),
          beforeEnter: (to, from, next) => {
            store.dispatch('getNGOApiFun')
            document.title = 'LBIL - My NGO And Support'
            next();
          }
        },
        {
          path: '/ngo/workspace/:Id',
          name: 'ngoWorkspace',
          component: () => import( /* webpackChunkName: "ngoWorkspace" */ './view/Private/ngo-workspace/index.vue'),
          props: (route) => ({
            to: route.query.to,
            stage: route.query.stage,
            bg: route.query.bg
          }),
          beforeEnter: (to, from, next) => {
            document.title = 'LBIL - NGO Workspace'
            next();
          }
        },
        {
          path: '/sg/workspace/:Id',
          name: 'sgWorkspace',
          component: () => import( /* webpackChunkName: "sgWorkspace" */ './view/Private/sg-workspace/index.vue'),
          props: (route) => ({
            to: route.query.to,
            stage: route.query.stage,
            bg: route.query.bg
          }),
          beforeEnter: (to, from, next) => {
            document.title = 'LBIL - Support Group Workspace'
            next();
          }
        },
        {
          path: '/online/therapy/',
          name: 'onlineTherapy',
          component: () => import( /* webpackChunkName: "onlineTherapy" */ './view/Private/online-therapy/index.vue'),
          props: (route) => ({
            to: route.query.to,
            stage: route.query.stage,
            bg: route.query.bg
          }),
          beforeEnter: (to, from, next) => {
            store.dispatch('getAppointmentListOfTherapyApiFun')
            document.title = 'LBIL - Online Therapy'
            next();
          }
        },
        {
          path: '/online/therapy/doctors/',
          name: 'onlineTherapyDoctors',
          component: () => import( /* webpackChunkName: "onlineTherapyDoctors" */ './view/Private/online-therapy-doctor-list/index.vue'),
          props: (route) => ({
            to: route.query.to,
            stage: route.query.stage,
            bg: route.query.bg
          }),
          beforeEnter: (to, from, next) => {
            store.dispatch('getOnlineTherapyDoctorsListFun')
            document.title = 'LBIL - Online Therapy Doctors'
            next();
          }
        },
        {
          path: '/doctors/',
          name: 'doctorsList',
          component: () => import( /* webpackChunkName: "doctorsList" */ './view/Private/doctors-list/index.vue'),
          props: (route) => ({
            to: route.query.to,
            stage: route.query.stage,
            bg: route.query.bg
          }),
          beforeEnter: (to, from, next) => {
            store.dispatch('getDoctorsFiltersListApiFun')
            store.dispatch('getDoctorsListApiFun')
            document.title = 'LBIL - Doctors'
            next();
          }
        },
        {
          path: '/doctors/:doctorID/',
          name: 'doctorsInfoById',
          component: () => import( /* webpackChunkName: "doctorsInfoById" */ './view/Private/doctor-info-page/index.vue'),
          props: (route) => ({
            to: route.query.to,
            stage: route.query.stage,
            bg: route.query.bg
          }),
          beforeEnter: (to, from, next) => {
            store.dispatch('getSelfUserInfoApiFun')
            document.title = 'LBIL - Doctor Info'
            next();
          }
        },
        {
          path: '/health/records/',
          name: 'healthRecords',
          component: () => import( /* webpackChunkName: "healthRecords" */ './view/Private/health-records/index.vue'),
          props: (route) => ({
            to: route.query.to,
            stage: route.query.stage,
            bg: route.query.bg
          }),
          beforeEnter: (to, from, next) => {
            store.dispatch('getHelthRecordsListApiFun')
            document.title = 'LBIL - Health Records'
            next();
          }
        },
        {
          path: '/appointment/book/:doctorID',
          name: 'appointmentBook',
          component: () => import( /* webpackChunkName: "appointmentBook" */ './view/Private/appointment/book-appointment/index.vue'),
          props: (route) => ({
            to: route.query.to,
            stage: route.query.stage,
            bg: route.query.bg
          }),
          beforeEnter: (to, from, next) => {
            document.title = 'LBIL - Appointment'
            next();
          }
        },
        {
          path: '/another/appointment/book/:doctorID',
          name: 'appointmentAnotherBook',
          component: () => import( /* webpackChunkName: "appointmentAnotherBook" */ './view/Private/appointment/book-another-appointment/index.vue'),
          props: (route) => ({
            to: route.query.to,
            stage: route.query.stage,
            bg: route.query.bg
          }),
          beforeEnter: (to, from, next) => {
            document.title = 'LBIL - Appointment'
            next();
          }
        },
        {
          path: '/profile/update/',
          name: 'profileUpdate',
          component: () => import( /* webpackChunkName: "profileUpdate" */ './view/Private/updatedProfile/index.vue'),
          props: (route) => ({
            to: route.query.to,
            stage: route.query.stage,
            bg: route.query.bg
          }),
          beforeEnter: (to, from, next) => {
            store.dispatch('getSelfUserInfoApiFun')
            document.title = 'LBIL - Profile'
            next();
          }
        },
        {
          path: '/video/call/one-to-one-peer/',
          name: 'videoCallOneToOnePeer',
          component: () => import( /* webpackChunkName: "videoCallOneToOnePeer" */ './components/private/one-to-one-call/index.vue'),
          guest: true,
          beforeEnter: (to, from, next) => {
            // store.dispatch('checkRoomValidIdFun',{room_id:to.hash}).then((res)=>{})
            // store.dispatch('getAcUserDetailsFun').then((res)=>{
            //   next();
            // })
            store.dispatch('getSelfUserInfoApiFun').then((res => {
              next();
            }))
            document.title = 'LBIL - Video Conference'
          }
        },
        {
          path: '/video/one-to-one-peer/left/room/',
          name: 'OneToOnePeerLeftRoom',
          component: () => import( /* webpackChunkName: "OneToOnePeerLeftRoom" */ './components/private/direct-call-from-user-left/index.vue'),
          guest: true,
          beforeEnter: (to, from, next) => {
            document.title = 'LBIL - Video User Left Room',
            next();
          }
        },
        {
          path: '/ngo/info/:ngoId',
          name: 'ngoInfoPageById',
          component: () => import( /* webpackChunkName: "ngoInfoPageById" */ './view/Private/ngo-info-page/index.vue'),
          guest: true,
          beforeEnter: (to, from, next) => {
            store.dispatch('getNGOInfoNByIdApiFun', {id:to.params.ngoId})
            document.title = 'LBIL - NGO Info',
            next();
          }
        },
        {
          path: '/support-group/info/:supportGroupId',
          name: 'supportGroupInfoPageById',
          component: () => import( /* webpackChunkName: "supportGroupInfoPageById" */ './view/Private/support-group-info-page/index.vue'),
          guest: true,
          beforeEnter: (to, from, next) => {
            store.dispatch('getSGInfoNByIdApiFun', {id:to.params.supportGroupId})
            document.title = 'LBIL - Support Group Info',
            next();
          }
        },
        {
          path: '/therapy/appointment/',
          name: 'onlineTherapyAppointment',
          component: () => import( /* webpackChunkName: "onlineTherapyAppointment" */ './components/private/therapy-for-book-appointment/index.vue'),
          guest: true,
          beforeEnter: (to, from, next) => {
            document.title = 'LBIL - Appointment For Therapy',
            next();
          }
        },
        {
          path: '/conference/',
          name: 'conference',
          component: () => import( /* webpackChunkName: "conference" */ './view/Private/jitsi-for-meetings-conf/index.vue'),
          guest: true,
          beforeEnter: (to, from, next) => {
            document.title = 'LBIL - Conference',
           next();
          }
        },
        {
          path: '/conference/user/left/room/',
          name: 'UserLeftRoomInConference',
          component: () => import( /* webpackChunkName: "UserLeftRoomInConference" */ './view/Private/conference-room-in-user-left/index.vue'),
          guest: true,
          beforeEnter: (to, from, next) => {
            document.title = 'LBIL - Conference User Left Room',
            next();
          }
        },
        {
          path: '/payment/history/',
          name: 'paymentHistory',
          component: () => import( /* webpackChunkName: "paymentHistory" */ './view/Private/payment-history/index.vue'),
          guest: true,
          beforeEnter: (to, from, next) => {
            store.dispatch('getPaymentHistoryFunc')
            document.title = 'LBIL - Payment History',
            next();
          }
        },
      ]
    }
  ]
})
// Routes use for without login. these are the name of routes not path
const openRoutes = ['publicPrescription', 'MainHome', 'userAgreement', 'privacyPolicy', 'forgotPw', 'contactUs', 'aboutUs', 'launchConferenceInfo', 'publicNgoInfo', 'publicNgoList']

// Routes use for without login
router.beforeEach((to, from, next) => {
  if(openRoutes.includes(to.name)) {
    next();
  }else if(store.getters.isAuthenticated && localStorage.getItem('token') != '' && localStorage.getItem('token') != null && localStorage.getItem('LoginedUserID') != '' && localStorage.getItem('LoginedUserID') != null) {
    next()
  }else{
    let redirectTo = to.path;
    let redirectToHash = to.hash;
    let redirectToFull = '?redirect=' + redirectTo + redirectToHash;
    next('/' + redirectToFull)
  }
})

/** Show loader when moving routes */
router.beforeResolve((to, from, next) => {
	if (to.name) {
		if (openRoutes.includes(to.name)) {
      document.getElementById("preloader").style.display = "flex";
		}
		next();
	}
});

/** Hide preloader after each routes */
router.afterEach((to, from) => {
	if (openRoutes.includes(to.name)) {
    setTimeout(() => {
      document.getElementById("preloader").style.display = "none";
    }, 200);
	}
});
export default router;